import { Chip, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getTicketById } from '../../Redux/actions';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';

export default function TicketDetails() {
    const { facilityId: facility_id, client_id: client_id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { ticket_id } = useParams();
    const [ticketData, setTicketData] = useState({});
    const [showLoader, setShowLoader] = useState(false)

    const ticketsQueryParams = localStorage.getItem("ticketsQueryParams");
    const digiTicketsQueryParams = localStorage.getItem("digiTicketsQueryParams");
    const clientTicketsQueryParams = localStorage.getItem("clientTicketsQueryParams");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setShowLoader(true)
        dispatch(getTicketById(ticket_id)).then((res) => {
            if (res && res.status === 200) {
                setShowLoader(false)
                const respData = res?.data?.ticket
                if (!respData) {
                    return;
                }
                setTicketData(respData)
            }
        })
    }

    const properties = [
        { label: 'Facility Name:', value: ticketData?.facility?.facility_name || '--' },
        { label: 'Assignee:', value: ticketData?.assignee || '--' },
        { label: 'Ticket ID:', value: ticketData?.id || '--' },
        { label: 'Status:', value: (ticketData?.status ? 
            (<Chip label={ticketData?.ex_data?.status?.name ? ticketData?.ex_data?.status?.name : ticketData?.status} sx={{ backgroundColor: `${ticketData?.ex_data?.status?.color_code}` }} />) : ('--'))},
        { label: 'Title:', value: ticketData?.title || '--' },
        { label: 'Description:', value: ticketData?.description || '--' },
        { label: 'Created Person:', value: ticketData?.ex_data?.created_person || '--' },
        { label: 'Created At:', value: new Date(ticketData?.created_at).toLocaleString() || '--' },
        { label: 'Owner Details:', value: ticketData?.ex_data?.details?.owners?.map(item => `${item?.full_name || '--' } : ${item?.email || '--'}`).join(', ')},
        { label: '', value: <a href={ticketData?.ex_url} target="_blank">View ticket details in Zoho</a> || '--' }
    ];

    let ticketDetails 

    if( showLoader ) {
        ticketDetails = (
            <Paper sx={{ padding: 2 }} >
                <Grid container item className='w3-padding' justifyContent='center'>
                    <CircularProgress />
                </Grid>
            </Paper>
        )
    } else if(ticketData && Object.keys(ticketData).length !== 0) {
        ticketDetails = (
            <Paper 
                sx={{
                    wordWrap: 'break-word',
                    padding: {
                    xs: 0,
                    sm: 2, 
                    md: 2  
                    }
                }}
                >
                {properties.map((property, index) => (
                    <div key={index}>
                        <Grid container item className='w3-padding'>
                            <Grid item xs={12} sm={6} md={6}>
                                <Typography sx={{ fontWeight: 'bold' }}>{property.label}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                {property.value}
                            </Grid>
                        </Grid>
                    </div>
                ))}
            </Paper>
        )
    } else {
        ticketDetails = (
            <Paper sx={{ padding: 2 }} >
                <Grid container item className='w3-padding' justifyContent='center'>
                    <h5 className='w3-text-grey'>
                        No Data Found
                    </h5>
                </Grid>
            </Paper>
        )    
    }
    
    return (
        <Grid 
            container 
            sx={{
                padding: {
                xs: '12px 8px', 
                sm: '12px 8px', 
                md: '12px 24px' 
                }
            }}
        >
            <Grid container item xs={12} justifyContent='center'>
                <h5 style={{ fontWeight: 'bold' }}>TICKET DETAILS</h5>
            </Grid>
            <Grid item xs={12}>
               {ticketDetails}
            </Grid>
            <Grid container item xs={12} justifyContent='flex-end' style={{ marginTop: '6px'}}>
                <Button
                    onClick={() => {
                        if ( digiTicketsQueryParams ) {
                            navigate(`/client/${client_id}/digitalisation/${facility_id}/tickets?${digiTicketsQueryParams?.toString()}`)
                        }else if ( ticketsQueryParams ) {
                            navigate(`/tickets?${ticketsQueryParams?.toString()}`)
                        }else if ( clientTicketsQueryParams ) {
                            navigate(`/client/${client_id}/client-tickets?${clientTicketsQueryParams?.toString()}`)
                        }else {
                            navigate(`/tickets`)
                        }
                    }}
                >Back to list</Button>
            </Grid>
        </Grid>
    )
}