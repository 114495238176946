import React from 'react';
import { ResponsiveBar } from '@nivo/bar'
import { Card, CircularProgress } from '@mui/material';

const SensorHealthBarChart = ({ sensorHealthData, showLoader, regionsCount, builingsCount, sensorTypeCount }) => {

    const keys = ["within_range", "no_data", "limit_exceeded"];
    const colors = {
        within_range: "#7fc97f",
        limit_exceeded: "#f98f32",
        no_data: "#5b5c5c"
    };

    const aggregatedData = {};

    sensorHealthData?.forEach(item => {
        const { facility_name, meter_status, meter_count } = item;

        if (!aggregatedData[facility_name]) {
            aggregatedData[facility_name] = {
                facility_name,
                within_range: 0,
                no_data: 0,
                limit_exceeded: 0
            };
        }
        aggregatedData[facility_name][meter_status] += meter_count;
    });
    const data = Object.values(aggregatedData);

    return (
        <Card style={{ height: 600, width: '100%', overflowX: 'auto', padding: 20 }}>
            {showLoader ? (
                <div className='w3-center'>
                    <CircularProgress />
                </div>
            ) : (
                    <div style={{ width: data.length > 0 ? `${Math.max(data.length * 20, 800)}px`: '100%', height: 450, padding: 16 }}>
                        {data.length > 0 ? (
                            <ResponsiveBar
                                data={data}
                                keys={keys}
                                indexBy="facility_name"
                                margin={{ top: 30, right: 130, bottom: 170, left: 60 }}
                                padding={0.4}
                                layout="vertical"
                                enableTotals={true}
                                colors={({ id }) => colors[id]}
                                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                axisTop={null}
                                axisRight={null}
                                enableGridX={false}
                                enableGridY={false}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 25,
                                    tickRotation: 45,
                                    // legend: 'Facility Name',
                                    legendPosition: 'middle',
                                    legendOffset: 130,
                                }}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'Meter Count',
                                    legendPosition: 'middle',
                                    legendOffset: -40,
                                }}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                labelTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                                legends={[
                                    {
                                        dataFrom: 'keys',
                                        anchor: 'bottom-left',
                                        direction: 'row',
                                        justify: false,
                                        translateX: -26,
                                        translateY: 160,
                                        itemWidth: 80,
                                        itemHeight: 14,
                                        itemsSpacing: 30,
                                        symbolSize: 14,
                                        itemDirection: 'left-to-right',
                                        itemOpacity: 0.85,
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemOpacity: 1,
                                                },
                                            },
                                        ],
                                    },
                                ]}
                                role="application"
                                ariaLabel="Nivo bar chart"
                                barAriaLabel={function (e) {
                                    return e.id + ": " + e.value + " in facility: " + e.indexValue;
                                }}
                            />
                        ) : (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <h5 className='w3-text-grey'>  No Data Found</h5>
                            </div>
                        )}
                    <div style={{ padding: '10px 32px 38px 32px' }}>
                        <h5>Insights:</h5>
                        <ul>
                            <li>Region {regionsCount ? `- ${regionsCount}` : ''}</li>
                            <li>Type of Building {builingsCount ? `- ${builingsCount}` : ''}</li>
                            <li>Type of Sensors {sensorTypeCount ? `- ${sensorTypeCount}` : ''}</li>
                        </ul>
                    </div>
                </div>
            )}
        </Card>
    );
};

export default SensorHealthBarChart;