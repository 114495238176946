import React, { useEffect, useState } from 'react';
import { ResponsivePie } from '@nivo/pie';

const PieChart = ({ chartData }) => {
    const [sensorParameters, setSensorParameters] = useState([]);
    const colorMapping = {
        "Air quality": '#b3de69',
        "Energy": '#80b1d3',
        "Equipment status": '#8dd3c7',
        "Level": '#fb8072',
        "Pressure": '#ffffb3',
        "Temperature": '#fdb462',
        "Water quality": '#bebada',
        "Water quantity": '#fccde5',
    };
    useEffect(() => {
        let data = [];
        chartData?.map((item, idx) => {
            data.push({
                id: item.meter_group.replace("_", " "),
                label: item.meter_group,
                value: item.meter_count,
                key: `${idx}-${item.meter_group}`
            });
        });
        setSensorParameters(data);
    }, [1]);

    return (
        <div style={{ height: 420, width: '100%' }}>
            <ResponsivePie
                data={sensorParameters}
                colors={({ id }) => colorMapping[id]}
                margin={{ top: -20, right: 50, bottom: 30, left: 50 }}
                startAngle={-90}
                enableArcLabels={false}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsOffset={-5}
                arcLinkLabelsDiagonalLength={15}
                arcLinkLabelsStraightLength={14}
                arcLinkLabelsThickness={2}
                arcLinkLabel="value"
                arcLinkLabelsColor={{ from: 'color' }}
                endAngle={90}
                innerRadius={0.7}
                cornerRadius={5}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.2
                        ]
                    ]
                }}
                defs={[
                    {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        size: 4,
                        padding: 1,
                        stagger: true
                    },
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10
                    }
                ]}
                legends={[
                    {
                        anchor: 'bottom',
                        direction: 'column',
                        justify: false,
                        translateX: 0,
                        translateY: 6,
                        itemWidth: 80,
                        itemHeight: 14,
                        itemsSpacing: 8,
                        symbolSize: 14,
                        itemDirection: 'left-to-right'
                    }
                ]}
            />
        </div>
    );
};

export default PieChart;